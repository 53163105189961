import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;

  const { tryLogin, getLoginUrl } = useAuthStore();

  const isLoggedIn = await tryLogin(to.query.redirectLoggedIn === 'true');

  // If the user is not logged in and is navigating to one of the dashboard pages (/mina-sidor/*)
  // we want to redirect them to the login page keeping the current dashboard path as the redirect path
  if (!isLoggedIn) {
    const localePath = useLocalePath();
    const dashboardPath = localePath({
      name: 'shop-dashboard',
    });
    const isDashboardPath = to.fullPath.startsWith(dashboardPath);
    if (isDashboardPath) {
      const loginUrl = getLoginUrl({
        redirectPath: to.fullPath,
      });

      // eslint-disable-next-line consistent-return
      return navigateTo(loginUrl, { external: true });
    }

    // eslint-disable-next-line consistent-return
    return navigateTo(localePath({ name: 'shop' }));
  }

  try {
    localStorage.setItem('hasLoggedInBefore', 'hasLoggedInBefore');
  } catch (err) {
    console.info('LocalStorage unavailable');
  }
});
